//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "compareData",
  data() {
    return {
      currentStore: "",
      startDate: "",
      endDate: "",
      basicData: {},
      lastBasicData: {},
      saleLoading: false,
      timeTxt: "",
      lastSaleLoading: false,
      lastStartDate: "",
      lastEndDate: "",
      salesStructureAnalysisData: {
        vol: {},
        val: {},
        per: {},
      },
    };
  },
  created() {
    // console.log('this.$storage.set',this.$storage.get('salesStructureAnalysisData'))
    // this.salesStructureAnalysisData=this.$storage.get('salesStructureAnalysisData')
    this.currentStore = this.$route.query.store;
    this.startDate = this.$route.query.startDate;
    this.endDate = this.$route.query.endDate;
    let start =
      this.startDate.substr(0, 4) +
      "-" +
      this.startDate.substr(4, 2) +
      "-" +
      this.startDate.substr(6, 2);
    let end =
      this.endDate.substr(0, 4) +
      "-" +
      this.endDate.substr(4, 2) +
      "-" +
      this.endDate.substr(6, 2);
    this.timeTxt = start + "至" + end;
    this.combineData()
    // this.lastStartDate = this.$moment(start)
    //   .subtract(1, "years")
    //   .format("yyyyMMDD");
    // this.lastEndDate = this.$moment(end)
    //   .subtract(1, "years")
    //   .format("yyyyMMDD");
    // console.log(this.lastStartDate, this.lastEndDate);
    // this.getData();
    // this.getLastData();
  },
  activated() {
    window.document.title = "门店业绩";
  },
  methods: {
    combineData() {
      var preData = this.$storage.get("salesStructureAnalysisData");
      console.log(preData)
      for (let index = 0; index < preData.length; index++) {
        if (preData[index].structureName == "同期") {
          this.salesStructureAnalysisData.val = preData[index];
        } else if (preData[index].structureName == "当期") {
          this.salesStructureAnalysisData.vol = preData[index];
        } else if (preData[index].structureName == "同比") {
          this.salesStructureAnalysisData.per = preData[index];
        }
      }
    },
    getData() {
      const that = this;
      let startDate = this.startDate;
      let endDate = this.endDate;
      const data = [
        {
          HIGH: endDate,
          LOW: startDate,
          LOW_FLAG: "1",
          OPTION: "CP",
          PARAMETER: "ZDATE_103",
          SIGN: "I",
        },
        {
          HIGH: "",
          LOW: this.currentStore,
          LOW_FLAG: "1",
          OPTION: "EQ",
          PARAMETER: "ZV_MD06",
          SIGN: "I",
        },
      ];
      this.saleLoading = true;
      // this.axios.post('/v1/bw/BwApi/' + this.apis['saleAnalysis_getSales'], data).then(res=>{
      this.axios
        .post(
          "/" +
            this.$ajaxPrefix.consumer +
            "/bw/BwApi/" +
            "saleAnalysis_getSales",
          data
        )
        .then((res) => {
          if (res && res.data && res.data.data) {
            that.basicData = res.data.data[0];

            that.saleLoading = false;
          }
        });
    },
    getLastData() {
      const that = this;
      let startDate = this.lastStartDate;
      let endDate = this.lastEndDate;
      const data = [
        {
          HIGH: endDate,
          LOW: startDate,
          LOW_FLAG: "1",
          OPTION: "CP",
          PARAMETER: "ZDATE_103",
          SIGN: "I",
        },
        {
          HIGH: "",
          LOW: this.currentStore,
          LOW_FLAG: "1",
          OPTION: "EQ",
          PARAMETER: "ZV_MD06",
          SIGN: "I",
        },
      ];
      this.lastSaleLoading = true;
      // this.axios.post('/v1/bw/BwApi/' + this.apis['saleAnalysis_getSales'], data).then(res=>{
      this.axios
        .post(
          "/" +
            this.$ajaxPrefix.consumer +
            "/bw/BwApi/" +
            "saleAnalysis_getSales",
          data
        )
        .then((res) => {
          if (res && res.data && res.data.data) {
            that.lastBasicData = res.data.data[0];
            that.lastSaleLoading = false;
          }
        });
    },
  },
};
